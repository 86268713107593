<template>
  <v-dialog
    :value="openedValue"
    max-width="700px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card class="pa-5">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="key"
              label="Clé"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="locale"
              label="locale"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="value"
              label="Texte"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="submit()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CreationModal',
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      key: null,
      value: null,
      locale: null,
    }
  },
  computed: {
    openedValue: {
      get () { return this.opened  },
      set (value) { this.$emit('update:opened', value) },
    },
  },

  methods: {
    close() {
      this.openedValue = false
      this.key = null
      this.value = null
      this.locale = null
    },
    submit() {
      this.$emit('submited', {
        key: this.key,
        locale: this.locale,
        value: this.value,
      })
      this.close()
    },
  },
}
</script>
